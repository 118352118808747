@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.header {
  padding: 15px 20px;
  height: 60px;

  position: sticky;
  top: 0;
  background-color: $whiteColor;

  z-index: 100;

  @include onTablet {
    padding: 15px 30px;
  }

  &__menu {
    padding: 0 20px;
    height: calc(100vh - 60px);
  
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;

    z-index: 100;

    background-color: $whiteColor;

    transform: translateX(-120%);

    transition: all 500ms;

    &--active {
      transform: translateX(0);
    }

    @include onTablet {
      padding: 0 30px;

      width: 60%;
    }
  }

  &__top {
    width: 100%;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  
    background-color: $whiteColor;
  }

  &__logo {
    width: 100%;

    font-family: "Reem Kufi Fun", sans-serif;
    font-size: 20px;
    font-weight: 900;
    text-align: left;

    &--red {
      color: $redMainColor;
    }
  }

  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  &__button {
    width: 25px;
    height: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__cart-info {
    position: absolute;

    transform: translate(10px, -10px);

    width: 12px;
    height: 12px;
    background-color: $redMainColor;
    color: $whiteColor;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cart-count {
    text-align: center;
    height: 8px;
    width: 10px;
    font-size: 8px;
    line-height: 8px;
  }
}