@import '../../styles/utils/variables';

.notification {
  padding: 10px;

  border: 1px solid $redMainColor;
  background-color: $redMainColor;
  border-radius: 4px;

  color: $whiteColor;

  display: flex;
  justify-content: space-between;
  align-items: center;

  opacity: 0;
  max-height: 0;
  transition: opacity 500ms,
              max-height 500ms;
  overflow: hidden;

  &--active {
    max-height: 600px;
    opacity: 1;
  }

  &__button {
    width: 16px;
    height: 16px;

    padding: 4px;
    border-radius: 50%;
    background-color: $whiteColor;
  }
}