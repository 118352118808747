@import '../../styles/utils/variables';

.order {
  padding-bottom: calc($cartActiveHeight - 20px);
  // background-color: $whiteColor;

  &__section {
    margin-bottom: 25px;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__subtitle {
    margin-bottom: 15px;
  }

  &__inputs-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__search {
    padding: 6px 10px;
    width: 20px;
    height: 20px;

    position: absolute;
    right: 0;
    z-index: 20;

    background-color: $whiteColor;

    transform: translateX(-10px);

    &--hidden {
      display: none;
    }
  }

  &__input {
    width: 100%;
  }

  &__select {
    margin-bottom: 20px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  &__search-results {
    position: absolute;
    top: 45px;

    z-index: 30;

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    background-color: $whiteColor;

    overflow: hidden;

    opacity: 0;
    max-height: 0;

    transition: opacity 300ms,
      max-height 300ms;

    &--active {
      opacity: 1;
      max-height: 800px;

      overflow: auto;
    }
  }

  &__search-item {
    padding: 5px 15px;

    &:nth-child(2n) {
      background-color: $silverLight;
    }
  }

  &__action {
    height: $cartActiveHeight;
    padding: 10px 20px;
    position: fixed;
    z-index: 10;

    bottom: 0;
    left: 0;
    right: 0;

    background-color: $whiteColor;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__total {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;

    &-info {
      font-weight: 600;
    }
  }

  &__button {
    margin-bottom: 15px;
  }

  &__notification {
    position: absolute;
    left: 0;
    right: 0;
    top: 95px;

    margin: 0 20px;
  }
}
