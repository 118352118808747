@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.card-slider {


  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 15px;
  }

  &__list {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  &__slides {
    display: flex;
    gap: 20px;

    transition: transform 400ms ease-in-out;

    @include onTablet {
      gap: 30px;
    }

    @include onDesktop {
      gap: 40px;
    }
  }

  &__item {
    flex-shrink: 0;
  }

  &__buttons {
    display: flex;
    gap: 5px;
  }

  &__button {
    width: 20px;
    height: 20px;

    border: 1px solid $silverBlue;
    border-radius: 4px;
    transition: border-color 300ms;

    &:hover {
      border-color: $silverDark;
    }

    &--disabled {
      border-color: $silverLight;
      cursor: default;

      &:hover {
        border-color: $silverLight;
      }
    }
  }
}