@import '../../styles/utils/mixins';
@import '../../styles/utils/variables';

.product {
  box-sizing: border-box;

  &__image {
    width: 100%;
  }

  &__info {
    margin-bottom: 5px;

    display: flex;
    gap: 5px;

    justify-content: space-between;
    align-items: center;
  }

  &__name {
    display: flex;
    align-items: center;

    min-height: 26px;
    font-size: 10px;
    font-weight: 600;

    text-align: left;

    @include onTablet {
      min-height: 31px;
      font-size: 12px;
    }

    @include onDesktop {
      min-height: 36px;
      font-size: 14px;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__prices {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__price {
    font-size: 10px;
    font-weight: 500;

    white-space: nowrap;

    &--currency {
      font-size: 8px;
    }

    @include onTablet {
      font-size: 12px;
    }

    @include onDesktop {
      font-size: 14px;
    }
  }

  &__price-discount {
    font-size: 10px;
    text-decoration: line-through;
    color: #988
  }

  &__button {
    width: 85px;

    @include onTablet {
      width: 110px;
    }

    @include onDesktop {
      width: 140px;
    }
  }

  &__fav {
    width: 25px;
    height: 25px;

    margin-right: 12px;

    @include onDesktop {
      width: 30px;
      height: 30px;
    }
  }
}