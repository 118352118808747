.fade {
  &-enter {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &-enter-active {
    opacity: 1;
  }
}
