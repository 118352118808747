@import './utils/variables';
@import './utils/mixins';
@import './fonts.scss';
@import './button.scss';
@import './link.scss';
@import './page.scss';
@import './reset.scss';
@import './input.scss';
@import './select.scss';
@import './fade.scss';


.main {
  padding: 0 20px;
  margin-top: 10px;
  margin-bottom: 30px;

  @include onTablet {
    padding: 0 30px;
  }

  @include onDesktop {
    padding: 0 40px;
  }

  &--cat {
    min-height: calc(100vh - 60px);
  }

  &__top {
    margin-bottom: 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__sort {
    width: 150px;
    height: 30px;
    font-size: 12px;
  }

  &__list {

  }

  &__home-link {
    margin-top: 10px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.overlay  {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 60;

  backdrop-filter: blur(10px);

  display: none;

  &--active {
    @include onTablet {
      display: block;
    }

    @include onDesktop {
      display: none;
    }
  }
}
