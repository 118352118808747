@import '../../styles/utils/variables';

.card {
  padding-bottom: 60px;

  &__gallery {
    margin-bottom: 30px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 5px;
  }

  &__id {
    font-size: 14px;
  }

  &__title {
    margin-bottom: 5px;

    font-size: 16px;
    text-align: left;
  }

  &__description {
    font-size: 14px;
    text-align: left;
  }

  &__price {
    font-size: 16px;
    font-weight: 700;
  }

  &__actions {
    width: 100%;
    height: 80px;

    padding: 0 20px 10px;

    border-top: 1px solid $silverLight;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;

    background-color: $whiteColor;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  &__fav {
    width: 25px;
    height: 25px;
  }

  &__button {
    width: 140px;
    height: 40px;
  }
}
