@import '../../styles/utils/variables';

.details {
  border-collapse: collapse;
  width: 100%;

  font-size: 12px;

  &__thead {
    background-color: $silverExtraLight;
  }

  &__row {
    &:nth-child(2n) {
      background-color: $silverExtraLight;
    }
  }

  &__img {
    width: 40px;
    height: 40px;
  }

  &__cell {
    border: 1px solid $silverLight;
    text-align: left;
    padding: 4px;
  }
}