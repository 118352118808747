@import "../../styles/utils/variables";
@import "../../styles/utils/mixins";

.products {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @include onTablet {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }

  @include onDesktop {
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }

  &__item {
    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity 300ms;
    }
  }
}