.page {
  &__title {
    text-align: center;
    margin-bottom: 10px;
  }

  &__subtitle {
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 2px;
  }

  &__section {
    margin-bottom: 15px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__nav {
    width: 25px;
    height: 25px;
  }

  &__col {
    display: flex;
    flex-direction: column;

    &--center {
      align-items: center;
      justify-content: center;
    }
  }

  &__back {
    margin-top: 15px;
    border-bottom: 1px solid $redMainColor;
  }

  &__list-transition {
    &-enter {
      opacity: 0.1;
    }
    &-enter-done {
      opacity: 1;
      transition: opacity 300ms;
    }
  }
}