@import '../../styles/utils/variables';

.nav {
  &__section {
    margin-bottom: 25px;
  }

  &__list, &__sublist {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list {
    font-size: 20px;
    color: $whiteColor;
  }

  &__sublist {
    font-size: 15px;

    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s, opacity 0.5s;

    &--active {
      opacity: 1;
      max-height: 500px;
    }
  }

  &__item,
  &__subitem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid $whiteColor;
    background-color: $mainColor;

    transition: all 300ms;
  }

  &__item {
    &:nth-child(1n):not(:last-child) {
      border-bottom: none;
    }
  }


  &__subitem {
    background-color: $whiteColor;
    border: 1px solid $mainColor;

    &:nth-child(1n):not(:last-child) {
      border-bottom: none;
    }
  }

  &__link,
  &__sublink {
    width: 100%;
    padding: 10px;
    color: $whiteColor;
  }

  &__sublink {
    margin-left: 15px;

    color: $mainColor;
  }

  &__button {
    padding: 10px 15px;
    font-size: 30px;
    font-weight: 600;
    line-height: 20px;
    transform: translateY(-2px);
  }

  &__search {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__pages {
    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &-item {
      display: flex;
    }

    &-link {
      padding: 5px 0;

      color: $mainColor;
      font-size: 16px;

      transition: all 300ms;

      &:hover,
      &--active {
        color: $redMainColor;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-link {
      color: $mainColor;
      font-size: 16px;

      transition: all 300ms;

      &:hover,
      &--active {
        color: $redMainColor;
      }
    }

    &-item {
      display: flex;
      align-items: center;
    }
  }
}