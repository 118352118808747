@import './styles/utils/variables';

.app {
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;

  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  margin: 0;

  // &__nav {
  //   padding: 0 10px;
  // }

  &__container {
    padding: 0 20px;
  }
}

// .footer {
//   height: 300px;
//   background-color: $blueMainColor;
// }

.subtitle {
  font-size: 18px;
  font-weight: 700;
}

.title {
  font-size: 20px;
  font-weight: 700;

}

.text {
  font-size: 16px;

  &--bold {
    font-weight: 700;
  }
}
