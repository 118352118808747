@import "./variables";

@mixin onTablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1440px) {
    @content;
  }
}