@import '../../styles/utils/variables';

.cart {
  padding-bottom: calc($cartActiveHeight - 20px);

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__nav {
    width: 25px;
    height: 25px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__item {
    box-sizing: border-box;

    padding: 5px;
    display: grid;
    column-gap: 5px;
    grid-template-columns: 2fr 4.5fr 1.5fr 1.5fr 0.5fr;
    align-items: center;
    justify-content: center;

    border: 1px solid $silverBlue;
    border-radius: 4px;

    &-transition {
      &-exit {
        opacity: 1;
      }
      &-exit-active {
        opacity: 0;
        transition: opacity 300ms;
      }
    }

    &-img {
      width: 60px;
    }

    &-name, &-count {
      font-size: 14px;
    }

    &-price {
      white-space: nowrap;
      font-size: 12px;
    }

    &-counter {
      margin-right: 4px;
      display: flex;
      justify-content: flex-end;
      gap: 5px;
      align-items: center;

      &-buttons {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      &-button {
        width: 20px;
        height: 20px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 18px;
        line-height: 18px;

        border: 1px solid $silverLight;
        border-radius: 4px;

        transition: border-color 400ms;

        &:hover {
          border-color: $silverBlue;
        }
      }
    }

    &-remove {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-ico {
        width: 14px;
        height: 14px;
        
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__action {
    height: $cartActiveHeight;
    padding: 10px 20px;
    position: fixed;
    z-index: 30;

    bottom: 0;
    left: 0;
    right: 0;

    background-color: $whiteColor;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__total {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;

    &-info {
      font-weight: 600;
    }
  }

  &__button-order {
    margin-bottom: 15px;
  }

}