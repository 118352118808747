.button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 10px;

  transition: all 500ms;

  font-size: 11px;
  line-height: 11px;

  border-radius: 4px;

  @include onTablet {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 14px;
  }

  @include onDesktop {
    font-size: 14px;
    line-height: 14px;
  }

  &--main {
    background-color: $redSecColor;
    color: $whiteColor;

    text-transform: uppercase;

    border: 1px solid $redMainColor;

    &:hover {
      background-color: $redMainColor;
    }

    &--added {
      background-color: $greenColor;
      border-color: $greenDarkColor;
      cursor: not-allowed;

      &:hover {
        background-color: $greenColor;
      }
    }

  }

  &--card {
    padding: 0;
    width: 120px;
    height: 35px;
    font-size: 14px;
    font-weight: 600;
  }

  &--order {
    font-size: 16px;
    font-weight: 600;

    width: 100%;
    height: 40px;
    background-color: $skyBlueMainColor;
    color: $whiteColor;

    text-transform: uppercase;

    border: 2px solid $blueMainColor;

    &:hover {
      background-color: $blueMainColor;
    }

    &--disabled {
      cursor: default;

      border-color: $silverBlue;
      background-color: $silverLight;
      color: $silverDark;

      &:hover {
        cursor: default;

        border-color: $silverBlue;
        background-color: $silverLight;
        color: $silverDark;
      }
    }
  }

  &--payment {
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    width: 100%;
    height: 40px;
    background-color: $whiteColor;
    color: $mainColor;

    text-transform: uppercase;

    border: 2px solid $silverLight;

    &:hover {
      border-color: $silverDark;
    }

    &--active {
      border-color: $blueMainColor;
      cursor: auto;

      &:hover {
        border-color: $blueMainColor;
      }
    }
  }
}