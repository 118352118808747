$mainColor: #000;
$whiteColor: #fff;
$redMainColor: #F00C18;
$redSecColor: #F77986;
$orangeMainColor: #FE4F19;
$orangeSecColor: #F9AF9b;
$yellowMainColor: #FEE719;
$yellowSecColor: #F9EC91;
$blueMainColor: #19A5FF;
$blueSecColor: #88D2FC;
$skyBlueMainColor: #13D1FF;
$skyBlueSecColor: #A9F3FF;

$silverExtraLight: #f1f4f4;
$silverLight: #dde2e3;
$silverMiddle: #d1d9dc;
$silverBlue: #c4d0d9;
$silverReg: #9d9c9a;
$silverDark: #72747a;

$greenColor: #47bb02;
$greenDarkColor: #119f11;

$h2FontSize: 16px;

$cartActiveHeight: 140px;