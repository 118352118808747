@import './utils/variables';

html {
  scroll-behavior: smooth;
}

body,
ul,
li,
h1,
h2,
h3,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: $mainColor;
  -webkit-tap-highlight-color: transparent;
}

button {
  all: unset;
  outline: none;

  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  &:active, &:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

input {
  outline: none;
}
