@import '../../styles/utils/variables';

.social {
  display: flex;
  gap: 5px;

  &__icon {
    width: 40px;
    height: 40px;
    
    background-color: $whiteColor;
    padding: 5px;
    border-radius: 50%;

    transition: all 300ms;

    &:hover {
      background-color: $redSecColor;
    }

    &--footer {
      background-color: $blueMainColor;
    }
  }
}