.link {
  color: $mainColor;
  font-size: 16px;

  transition: all 300ms;

  &:hover,
  &--active {
    color: $redMainColor;
  }
}
