.input {
  height: 40px;
  padding: 0 15px;
  border: 2px solid $silverBlue;
  border-radius: 4px;

  transition: all 300ms;

  &:hover {
    border-color: $silverDark;
  }

  &:focus {
    outline: none;
    border-color: $silverReg;
  }

  &--disabled {
    cursor: default;
    border-color: $silverLight;

    &:hover {
      border-color: $silverLight;
    }
  }

  &--danger {
    border-color: $redSecColor;
  }
}
