@import './styles/utils/variables';
@import './styles/main.scss';

* {
  box-sizing: border-box;
}

.body--menu {
  overflow: hidden;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

