@import '../../styles/utils/variables';

.search {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid $mainColor;

  transition: all 300ms;

  &:hover, &--active {
    border-color: $redSecColor;
  }

  &-container {
    width: 101%;
    display: flex;
    flex-direction: column;
    align-items: left;

    position: relative;
  }

  &__input {
    font-family: inherit;
    font-size: 16px;

    height: 35px;
    width: 100%;

    border: none;

    &::placeholder {
      color: $mainColor;
    }
  }

  &__button {
    width: 20px;
    height: 20px;
  }
}

.results {
  width: 100%;

  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;

  background-color: $whiteColor;

  position: absolute;
  left: 0;
  right: 0;
  top: 37px;

  z-index: 60;

  opacity: 0;
  min-height: 0px;

  transition: opacity 400ms ease-in-out;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }

  &--active {
    opacity: 1;
  }

  &__transition {
    &-enter {
      opacity: 0;
    }
    
    &-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }
    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity 500ms ease-in;
    }
  }

  &__item {
    padding: 10px 0;
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 10px;
    align-items: center;

    z-index: 60;

    border-bottom: 1px solid $mainColor;
  }

  &__name {
    display: flex;
    justify-content: start;
  }

  &__img {
    width: 60px;
  }

  &__all {
    margin-top: 10px;
    color: $mainColor;

    border-bottom: 1px solid $mainColor;
  }

  &__empty {
    margin-top: 10px;
    color: $mainColor;
  }

  &__price {
    white-space: nowrap;
  }
}
