@import '../../styles/utils/variables';

.gallery {
  display: flex;
  flex-direction: column;

  &__main {
    padding: 5px;
    width: 100%;
  }

  &__img {
    width: 100%;
  }

  &__thumbnails {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  &__tbn {
    padding: 5px;
    width: 75px;

    border: 1px solid $silverLight;
    border-radius: 4px;

    &--active {
      border-color: $silverReg;
    }
  }
}
